$(function(){
  jQuery('.lang-select').click(function () {
    var theLang = jQuery(this).attr('data-lang');
    jQuery('.goog-te-combo').val(theLang);
    window.location = jQuery(this).attr('href');
    location.reload();
  });
  $.get('currency.php', function(resp){
     var marquee = "";
     if (resp != "") {
       var currencies = $.parseJSON(resp);
       for (var country in currencies) {
         var currency = currencies[country];
         var countries = country.split(',');
          marquee += "<span class='"+ countries[0] +"'></span><span class='"+ countries[1] +"'></span> " + 
                    "<span class='country_form'>" + countries[0] + "</span><span class='country_to'>" + countries[1] + "</span>" + 
                    "<span class='currency'>" + currency + "</span>";
          $('#currency').html(marquee).marquee({duration: 15000});
       }
     }
  });
});